/* Cards */


/* Card Section Header */

.section-cards {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.section-cards .section-cards__t-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  gap: .5rem;
}

.t-container-h2 {
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  color: var(--gray);
  transition: 300ms;
}

.t-container-h2.is-active {
  color: var(--firstcolor);
  font-weight: 800;
}

/* Update Button */

.section-cards__btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: .5rem;
}


.section-cards__btn-container .update-btn {
  font-size: .8rem;
  color: var(--black);
}

.cameraBtn {
  background-color: unset;
  width: 100%;
}