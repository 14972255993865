/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Reusable styles for Brotherhood Pages */

@import "./app/tabs/brotherhood/pages/css/brotherhood-pages.scss";

/* Reusable animations */

@import "./app/portal-tabs/brother/pages/css/animations.css";

/* Reusable Edit Modals */

@import "./app/portal-tabs/brother/pages/css/modalEdit.css";

/* Reusable Cards Brother Component */

@import "./app/portal-tabs/brother/pages/css/cards.css";

// Import Quill theme
@import "~quill/dist/quill.bubble.css";
// or
@import "~quill/dist/quill.snow.css";


html {
  background-color: var(--white);
}

// App Body

body {
  background-color: var(--white);
  height: 100%;
}

.ion-page {
  background-color: var(--white);
}

.ion-content {
  background-color: var(--white);
}

// Homepage

.section {
  background-color: var(--white);
  padding: 0rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  // height: 100%;
}

.section.h-100 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 30vh);
}

.section.h-50 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 50vh);
}

@media screen and (min-width: 350px) {
  .section {
    padding: 0rem 1rem;
  }
}

.section::before {
  content: "";
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-image: var(--background-heraldry);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 50%, contain;
  background-blend-mode: multiply;
  background-attachment: fixed;
  opacity: 0.099999;
  z-index: -1;
}

.section-title {
  text-transform: uppercase;
  font-size: 0.9rem;
  text-align: center;
  color: var(--firstcolor);
  font-weight: 800;
}

@media screen and (min-width: 340px) {
  .section-title {
    font-size: 1rem;
  }
}

@media screen and (min-width: 400px) {
  .section-title {
    font-size: 1.25rem;
  }
}

// Swipper Pagination Bullets

.swiper-pagination-bullets {
  top: 0 !important;
  bottom: unset !important;
}

.swiper-pagination-bullet {
  background-color: var(--black);
}

.swiper-pagination-bullet-active {
  background-color: var(--firstcolor);
}

// Scroll Bar

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  display: none;
}

*::-webkit-scrollbar-track {
  display: none;
}

*::-webkit-scrollbar-thumb {
  display: none;
}

/* Number Inputs */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}


// Custom Dialogs Modal

ion-modal.example-modal {
  --width: 90vw;
  --height: 20vh;
  --min-width: 250px;
  --max-width: 90vw;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --background: var(--white);
}

ion-modal.example-modal h1 {
  margin: 20px 20px 10px 20px;
}

ion-modal.example-modal ion-icon {
  margin-right: 6px;
  width: 1.5rem;
  height: 1.5rem;
  padding: 4px 0;
  color: var(--background-checkbox);
}

ion-modal.example-modal .wrapper {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

ion-modal.badge-modal {
  background-color: rgba(0, 0, 0, 0.4);
}

// Modal User Options
ion-modal.modal-options {
  --width: 90vw;
  --height: 20vh;
  --min-width: 250px;
  --max-width: 90vw;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --background: var(--white);
  --backdrop-opacity: 0.5;
  --backdrop-blur: 10px;
}

ion-modal.modal-options .wrapper {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

// Modal Wedding Booking
ion-modal.modal-weeding {
  --width: 90vw;
  --height: 20vh;
  --min-width: 250px;
  --max-width: 90vw;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --background: var(--white);
}

ion-modal.modal-weeding .wrapper {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  // background-image: var(--bgWeddingModal);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: multiply;
}
ion-modal.titularsModal .wrapper {
  --width: 100vw;
  --height: 100vh;
}

// Modal Wedding Booking
ion-modal.titularsModal {
  --width: 100vw;
  --height: 100vh;
}

ion-datetime {
  --background: var(--background-color);
  // --background-rgb: 92, 94, 92;
  border-radius: 16px;
  box-shadow: rgba(var(--background-color), 0.8) 0px 10px 15px -3px;
}

.ql-editor {
  text-align: justify !important;
  white-space: inherit;
  color: var(--black);
}

.ql-align-center {
  text-align: center !important;
  font-weight: 100;
  line-height: 20px !important;
}

.ql-align-center img,
.ql-align-justify img {
  border-radius: .5rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .2);
}

// Request Button & add to badge Button

.request-button {
  background: var(--firstcolor);
  width: max-content;
  margin: 0 auto;
  padding: 0 1rem;
  border-radius: 0.5rem;
  color: var(--white);
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 1px;
}
