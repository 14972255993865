// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  --ion-background-color: var(--white);
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;


  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  // Ghercof Colors Themes

  /** dark **/
  --ion-color-dark: var(--white);
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: var(--firstcolor);
  --ion-color-dark-contrast-rgb: 244, 245, 248;
  --ion-color-dark-shade: #9d9fa6;
  --ion-color-dark-tint:  #9d9fa6;

  /** backgroundButton **/
  --ion-color-light: var(--firstcolor);
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: var(--white);
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: var(--firstcolor-alt);
  --ion-color-light-tint: var(--background-navbar-icons);


  /** Global Variables **/

  --background-heraldry: url("../assets/img/heraldica.png");
  --background-image-brother-home: url('../assets/img/brother-home.jpg');
  --background-image-brotherhood-home: url('../assets/img/home.jpg');
  --toggle-icon-brotherhood: url("../assets/icon/hands.svg");
  --toggle-icon-brothersportal: url("../assets/icon/nazarene.svg");
  --heraldry: url("../assets/img/heraldica.png");
  --visa: url('../assets/cards-img/visa.png');
  --mastercard: url('../assets/cards-img/mastercard.png');
  --amex: url('../assets/cards-img/amex.png');
  --pdf: url('../assets/icon/pdf.png');

  /** Default Blue **/

  /* Change favorite color */
  /* Blue = 217 - Green = 154 - Bordeaux = 346 - Purple = 267 - Black = 225 */
  // --color: 154;
  // /* HSL color mode */
  // --firstcolor: hsl(var(--color), 85%, 24%);
  // --firstcolor-alt: hsl(var(--color), 85%, 56%);
  // --icons-color: hsl(var(--color), 86%, 24%);
  // --icon-active: hsl(var(--color), 36%, 74%);
  // --background-toggle: hsl(var(--color), 80%, 84%);
  // --background-navbar-icons: hsl(var(--color), 80%, 84%);
  // --box-shadow-animation: hsl(var(--color), 85%, 24%, 0.7);
  // --box-shadow-animation-black: hsl(var(--color), 80%, 84%, 0);
  // --primary-button: hsl(var(--color), 80%, 42%);
  // --primary-button-hover: hsl(var(--color), 85%, 44%);
  // --black: hsl(var(--color), 85%, 10%);
  // --white: hsl(var(--color), 85%, 98%);
  // --gray: hsl(var(--color), 24%, 85%);
  // --background-navbar: hsl(var(--color), 100%, 98%);
  // --background-color: hsl(var(--color), 36%, 60%);
  // --background-item-pay: hsl(var(--color), 85%, 95%);
  // --background-checkbox: hsl(var(--color), 80%, 65%);
  // --background-pulse: hsl(var(--color), 70% , 75%);
  // --background-date: hsl(var(--color), 86%, 18%);
  // --btn-disabled: hsl(var(--color), 10%, 85%);
  // --color-disabled: hsl(var(--color), 100%, 85%);
  // --uploadBtn-disabled: hsl(var(--color), 40%, 95%);
  // --colorUploadBtn-disabled: hsl(var(--color), 10%, 88%);
  // --box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  // --box-shadow-card: 0px 10px 20px rgba(0, 0, 0, 0.2);
  // --box-shadow-card-meet: 0px 8px 10px rgba(0,0,0, .1);
  // --box-shadow-toggle: inset 0px 0px 8px rgba(0, 0, 0, 0.25);
  // --box-shadow-toggle-ball: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // --box-shadow-pay-item: 0px 4px 10px rgba(0, 0, 0, 0.25);
  // --gold-text: var(--black);
  // --gold-btn: var(--background-checkbox);
  // --segment-btn: var(--white);
  // --color-btn: var(--firstcolor);


  /** Black Theme **/

  --color: 225;
  /* HSL color mode */
  --firstcolor: hsl(var(--color), 36%, 4%);
  --firstcolor-alt: hsl(var(--color), 36%, 56%);
  --icons-color: hsl(var(--color), 36%, 24%);
  --icon-active: hsl(var(--color), 36%, 74%);
  --background-toggle: hsl(var(--color), 20%, 84%);
  --background-navbar-icons: hsl(var(--color), 25%, 80%);
  --box-shadow-animation: hsl(var(--color), 35%, 24%, 0.7);
  --box-shadow-animation-black: hsl(var(--color), 30%, 84%, 0);
  --primary-button: hsl(var(--color), 36%, 4%);
  --primary-button-hover: hsl(var(--color), 20%, 14%);
  --black: hsl(var(--color), 36%, 5%);
  --white: hsl(var(--color), 5%, 95%);
  --gray: hsl(var(--color), 5%, 85%);
  --background-navbar: hsl(var(--color), 20%, 84%);
  --background-color: hsl(var(--color), 36%, 25%);
  --background-item-pay: hsl(var(--color), 15%, 90%);
  --background-checkbox: hsl(var(--color), 20%, 20%);
  --background-pulse: hsl(var(--color), 30% , 25%);
  --btn-disabled: hsl(var(--color), 10%, 85%);
  --color-disabled: hsl(var(--color), 100%, 85%);
  --uploadBtn-disabled: hsl(var(--color), 40%, 95%);
  --colorUploadBtn-disabled: hsl(var(--color), 10%, 88%);
  --box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  --box-shadow-card: 0px 10px 20px rgba(0, 0, 0, 0.2);
  --box-shadow-card-meet: 0px 8px 10px rgba(0,0,0, .1);
  --box-shadow-toggle: inset 0px 0px 8px rgba(0, 0, 0, 0.25);
  --box-shadow-toggle-ball: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --box-shadow-pay-item: 0px 4px 10px rgba(0, 0, 0, 0.25);
  --gold-text: #eec22f;
  --gold-btn: #eec22f;
  --segment-btn: ;
  --segment-btn: var(--black);
  --color-btn: #eec22f;
}
