@keyframes pulse-gradient {
    0% {
        box-shadow: 0 0 0 0 var(--box-shadow-animation);
    }

    70% {
        box-shadow: 0 0 0 7px var(--box-shadow-animation-black);
    }

    100% {
        box-shadow: 0 0 0 0 var(--box-shadow-animation-black);
    }
}