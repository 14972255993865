.modal-header {
  background-image: var(--bg-header-modal);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: screen;
  background-color: var(--firstcolor);
  height: 30%;
  object-fit: cover;
}

.ion-card-header {
  display: flex;
  width: 100%;
  background: var(--firstcolor);
  justify-content: space-between;
  padding: 1rem;
}

.modal-body {
  padding: 0 .25rem;
  background-color: var(--white);
}

.modal-content {
  border: 2px solid var(--background-checkbox);
  background-color: var(--background-item-pay);
  box-shadow: var(--box-shadow-pay-item);
  border-radius: .6rem;
}

.modal-content .modal-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: .5rem 0;
  border-bottom: 1px solid var(--background-navbar-icons);
}

.modal__btn-container {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.modal-row .modal__input-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.modal-row .modal__label {
  font-weight: 800;
  color: var(--firstcolor);
}

.modal-row .modal__data {
  text-align: start;
  color: var(--black);
  font-weight: 500;
  font-size: .8rem;
}

@media screen and (min-width: 360) {
  .modal-row .modal__data {
    font-size: .9rem;
  }
}

@media screen and (min-width: 400) {
  .modal-row .modal__data {
    font-size: 1rem;
  }
}