.section-title-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
}

.section-icon {
    position: absolute;
    width: 1rem;
    height: 1rem;
    z-index: 3;
    left: 0;
    cursor: pointer;
    outline: none;
}
